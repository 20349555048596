import React from "react";

/* our pages */
const Login = React.lazy(() => import("./pages/login"));
const ResetPassword = React.lazy(() => import("./pages/resetpassword"));

const route = [
  { path: "/login", exact: true, name: "Login", component: Login },
  {
    path: "/forgotpassword",
    exact: true,
    name: "Forgot Password",
    component: ResetPassword,
  },
];

export default route;
